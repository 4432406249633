<template>
  <div class="page">
    <el-container>
      <el-header>
        <Head :slide="slide" @logout="logout"></Head>
       
      </el-header>
      <el-main>
        <div class="">
            <ul class="bread-crumb">
                <li class="item item-1 fill" @click="$router.push('/post/upload?id=' + id)">
                     {{$t('report.post') + $t('report.upload')}}
                </li>
                <li class="item item-2 fill">
                     {{$t('report.post') + $t('report.scale')}}
                </li>
                <li class="item item-3">
                     {{$t('report.post') + $t('report.level')}}
                </li>
                <li class="item item-3">
                     {{$t('report.post') + $t('report.measure')}}
                </li>
                <li class="item item-3">
                     {{$t('report.post') + $t('report.install')}}
                </li>
                 <li class="item item-4">
                     {{$t('report.post') + $t('report.stand')}}
                </li>
                <li class="item item-5">
                     {{$t('report.post') + $t('report.create')}}
                </li>
            </ul>
            <div class="tabs">
                <input
                type="button"
                :value="$t('report.front')"
                :class="['tab', {'active': step == 0}]"
                />
                <input
                type="button"
                :value="$t('report.side')"
                :class="['tab', {'active': step == 1}]"
                />
            </div>
            <div class="main">
                <div class="canvas-box" style="position: relative">
                    <div id="container" :style="{'width': oldWidth + 'px', 'height': oldHeight + 'px', 'overflow': 'auto'}">
                        <v-stage :config="configKonva" ref="stage">
                            <v-layer ref="layer">
                                <v-image ref="xRay" :config="stageImage"/>
                                <v-group ref="rulerGroup" :config="rulerGroup">
                                    <v-line ref="ruler" :config="configLine"></v-line>
                                    <v-rect ref="rect1" :config="configRect1"></v-rect>
                                    <v-rect ref="rect2" :config="configRect2"></v-rect>
                                </v-group>
                                <v-group ref="circleGroup" :config="circleGroup">
                                    <v-circle ref="circle" :config="configCircle"></v-circle>
                                    <v-circle ref="center" :config="configCenter"></v-circle>
                                </v-group>
                                <v-group ref="toolGroup" :config="configToolGroup">
                                    <v-line ref="tool" :config="tool"></v-line>
                                    <v-rect ref="rect3" :config="configRect3"></v-rect>
                                    <v-rect ref="rect4" :config="configRect4"></v-rect>
                                    <v-text ref="toolText" :config="toolText"></v-text>
                                </v-group>
                            </v-layer>
                            <LineGride ref="lineGrideRef" :stagePos="{x:0,y:0}" :scale="lineSlide" :CanvasWidth="configKonva.width" :CanvasHeight="configKonva.height"></LineGride>
                        </v-stage>
                        
                    </div>
                    <div class="btn-restore" @click="onRestore">{{$t('report.restore')}}</div>
                     <Zoom class="zoom"
                    @onIncrease="onIncrease"
                    @onReset="onReset"
                    @onReduce="onReduce"
                    ></Zoom>
                    
                </div>
               <!-- <canvas id="canvsa" :width="widthIn" :height="heightIn"></canvas> -->
               <div class="right-side">
                   
                    <h2 class="content-title">
                        <div class="title">
                            <span>{{$t('report.params')}}</span>
                        </div>
                    </h2>
                    <el-form
                    :model="ruleForm"
                    ref="ruleForm"
                    label-width="100px"
                    class="form"
                    label-position="left"
                    >   
                        <div v-if="this.verify">
                            <el-form-item :label="$t('report.full')+'（mm）'" label-width="150px"><span>{{length}}mm</span></el-form-item>
                            <div class="btn-submit" @click="save">{{$t('common.save')}}</div>
                            <div class="btn-cancel" @click="remeasure">{{$t('report.remeasure')}}</div>
                        </div>
                        <div v-else>
                            <el-form-item :label="$t('report.toolType')+':'"  v-show="!this.verify" >
                                <el-radio-group v-model="ruleForm.tool">
                                    <el-radio class="radio" label="0">{{$t('report.ruler')}}</el-radio>
                                    <el-radio class="radio" label="1">{{$t('report.circle')}}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item :label="ruleForm.tool == '0' ?  $t('report.length') + '（mm）:' : $t('report.diameter') +'（mm）:'" 
                            v-show="!this.verify" >
                                <el-input v-model="ruleForm.width" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                            </el-form-item>
                            <div class="btn-submit" @click="submit">{{$t('common.confirm')}}</div>
                            <div class="text-upload" @click="lastStep">{{$t('report.again')}}</div>
                        </div>
                        
                    </el-form>
                      
               </div>
            </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
// import Konva from 'konva';
import Zoom from '../../components/Zoom';
import LineGride from '../../components/LineGride';
export default {
    inject: ['reload'],
    components:{
        Zoom, LineGride
    },
    data(){
        return{
            id: '', //病例id 
            ruleForm: {
                width: '', //长度
                tool: '0', //测量工具类型
            },
            step: 0, //当前步骤 0:正位 1:侧位
            stage: null, //画布
            layer: null, //画笔
            imageObj: new Image(), //初始图片对象
            oldWidth: document.documentElement.clientWidth * 0.75, // 画布初始宽度
            oldHeight: document.documentElement.clientHeight * 0.66, // 画布初始高度
            widthIn: document.documentElement.clientWidth * 0.75, // 当前画布宽度
            heightIn: document.documentElement.clientHeight * 0.66, // 当前画布高度
            xRay: null, //当前图像
            ruler: null, //尺子工具
            rulerWidth: 5, //尺子宽度
            circle: null, //球形工具
            tr1: null, //图形编辑工具
            tr2: null, //尺子编辑工具
            tr3: null, //球形编辑工具
            multiple: 1, //缩放倍数
            scale: 1,
            params:[], 
            base: [],
            verify: false, //是否核验
            length: 0, //实际长度
            proportion: 1, //当前比例
            slideText: '',
            slide: '',
            toolGroup: null, //长度测量工具
            lineSlide: 10,
            lineWidth: document.documentElement.clientWidth * 0.75, // 画布初始宽度
            lineHeight: document.documentElement.clientHeight * 0.66, // 画布初始高度
        }
    },
    computed: {
        //画布配置
        configKonva(){ 
            return {
                width: this.widthIn,  
                height: this.heightIn,
                // draggable: true //是否拖拽
                
  			}
        },
        //图片配置
  		stageImage () { 
            return {
                x: this.configKonva.width / 2 ,
                y: this.imageObj.height / 2 + 65,
                // rotation: 0, //旋转角度
                // draggable: true, 
                image: this.imageObj,
                offset: {
                    x: this.imageObj.width / 2,
                    y: this.imageObj.height / 2
                },
            }
  		},
        //尺子测量工具
        rulerGroup(){
            let that = this;
            return {
                visible: !this.verify && this.ruleForm.tool == '0'? true:false,
                draggable: true,
                dragBoundFunc: function(pos){
                    return {
                        x: that.$refs.rect1.getNode().x() + pos.x / that.scale < 0
                        ? - that.$refs.rect1.getNode().x() * that.scale 
                        : that.$refs.rect2.getNode().x() + pos.x / that.scale > that.widthIn 
                            ? this.absolutePosition().x
                            : pos.x,
                        y: that.$refs.rect1.getNode().y() + pos.y / that.scale < 0 
                        ? - that.$refs.rect1.getNode().y() * that.scale 
                        : that.$refs.rect2.getNode().y() + pos.y / that.scale > that.heightIn 
                            ? this.absolutePosition().y
                            : pos.y
                    }
                }
            }
        },
        configLine(){ 
            let initX = this.ruler ? this.$refs.rect1.getNode().x() : this.oldWidth - 150;
            let initY = this.ruler ? this.$refs.rect1.getNode().y() : this.imageObj.height / 2 + 65;
            let initX2 = this.ruler ? this.$refs.rect2.getNode().x() : initX;
            let initY2 = this.ruler ? this.$refs.rect2.getNode().y() : initY + 200;
            let points = [initX, initY, initX2, initY2]
            return {
                points,
                stroke: '#03C3A0',
                strokeWidth: this.rulerWidth / this.scale,
                // draggable: true,
  			}
        },
        configRect1(){
            let x = this.$refs.rect1 ? this.$refs.rect1.getNode().x() : this.oldWidth - 150;
            let y = this.$refs.rect1 ? this.$refs.rect1.getNode().y() : this.imageObj.height / 2 + 65;
            return {
                x,
                y,
                width: this.rulerWidth / this.scale,
                height: this.rulerWidth / this.scale,
                fill: '#FFFFFF',
                offset: {
                    x: this.rulerWidth / this.scale / 2,
                    y: this.rulerWidth / this.scale / 2
                },
                draggable: true,
                dragBoundFunc:(pos)=>{
                    // this.configRect1.x = pos.x - this.ruler.x() - this.stage.x();
                    // this.configRect1.y = pos.y - this.ruler.y() - this.stage.y();
                    this.configLine.points[0] = pos.x;
                    this.configLine.points[1] = pos.y;
                    return pos;
                }
            }
        },
        configRect2(){
            let x = this.$refs.rect2 ? this.$refs.rect2.getNode().x() : this.oldWidth - 150;
            let y = this.$refs.rect2 ? this.$refs.rect2.getNode().y() : this.imageObj.height / 2 + + 265;
            return {
                x,
                y,
                width: this.rulerWidth / this.scale,
                height: this.rulerWidth / this.scale,
                fill: '#FFFFFF',
                offset: {
                    x: this.rulerWidth / this.scale / 2,
                    y: this.rulerWidth / this.scale / 2
                },
                draggable: true,
                dragBoundFunc:(pos)=>{
                    // this.configRect2.x = pos.x - this.ruler.x() - this.stage.x();
                    // this.configRect2.y = pos.y - this.ruler.y() - this.stage.y();
                    this.configLine.points[2] = pos.x;
                    this.configLine.points[3] = pos.y;
                    return pos;
                }
            }
        },
        //实际长度测量工具
        configToolGroup(){
            let that = this;
            return {
                visible: this.verify ? true:false,
                draggable: true,
                dragBoundFunc: function(pos){
                    return {
                        x: that.$refs.rect3.getNode().x() + pos.x / that.scale < 0
                        ? - that.$refs.rect3.getNode().x() * that.scale 
                        : that.$refs.rect4.getNode().x() + pos.x / that.scale > that.widthIn 
                            ? this.absolutePosition().x
                            : pos.x,
                        y: that.$refs.rect3.getNode().y() + pos.y / that.scale < 0 
                        ? - that.$refs.rect3.getNode().y() * that.scale 
                        : that.$refs.rect4.getNode().y() + pos.y / that.scale > that.heightIn 
                            ? this.absolutePosition().y
                            : pos.y
                    }
                }
            }
        },
        tool(){ 
           
            let initX = this.toolGroup ? this.$refs.rect3.getNode().x() : this.oldWidth - 150;
            let initY = this.toolGroup? this.$refs.rect3.getNode().y() : this.imageObj.height / 2 + 65;
            let initX2 = this.toolGroup ? this.$refs.rect4.getNode().x() : initX;
            let initY2 = this.toolGroup ? this.$refs.rect4.getNode().y() : initY + 200;
            let points = [initX, initY, initX2, initY2]
            return {
                points,
                stroke: '#03C3A0',
                strokeWidth: this.rulerWidth / this.scale,
                // visible: false,
  			}
        },
         configRect3(){
            let x = this.$refs.rect3 ? this.$refs.rect3.getNode().x() : this.oldWidth - 150;
            let y = this.$refs.rect3 ? this.$refs.rect3.getNode().y() : this.imageObj.height / 2 + 65;
            return {
                x,
                y,
                width: this.rulerWidth / this.scale,
                height: this.rulerWidth / this.scale,
                fill: '#FFFFFF',
                offset: {
                    x: this.rulerWidth / this.scale / 2,
                    y: this.rulerWidth / this.scale / 2
                },
                draggable: true,
                dragBoundFunc:(pos)=>{
                    let tool = this.$refs.toolGroup.getNode();
                    // this.configRect3.x = pos.x - tool.x() - this.stage.x();
                    // this.configRect3.y = pos.y - tool.y() - this.stage.y();
                    // this.$refs.tool.getNode().points([
                    //     pos.x - tool.x() - this.stage.x(),
                    //     pos.y - tool.y() - this.stage.y(),
                    //     this.$refs.tool.getNode().points()[2],
                    //     this.$refs.tool.getNode().points()[3],
                    // ])
                    this.length = this.getLenght(this.$refs.tool.getNode())
                    return pos;
                }
            }
        },
        configRect4(){
            let x = this.$refs.rect4 ? this.$refs.rect4.getNode().x() : this.oldWidth - 150;
            let y = this.$refs.rect4 ? this.$refs.rect4.getNode().y() : this.imageObj.height / 2 + 265;
            return {
                x,
                y,
                width: this.rulerWidth / this.scale,
                height: this.rulerWidth / this.scale,
                fill: '#FFFFFF',
                offset: {
                    x: this.rulerWidth / this.scale / 2,
                    y: this.rulerWidth / this.scale / 2
                },
                draggable: true,
                dragBoundFunc:(pos)=>{
                    let tool = this.$refs.toolGroup.getNode();
                    // this.configRect4.x = pos.x - tool.x() - this.stage.x();
                    // this.configRect4.y = pos.y - tool.y() - this.stage.y();
                    // this.$refs.tool.getNode().points([
                    //     this.$refs.tool.getNode().points()[0],
                    //     this.$refs.tool.getNode().points()[1],
                    //     pos.x - tool.x() - this.stage.x(),
                    //     pos.y - tool.y() - this.stage.y(),
                    // ])
                    this.length = this.getLenght(this.$refs.tool.getNode());
                    return pos;
                }
            }
        },
        toolText(){
            return {
                x: this.configRect3.x + (this.configRect4.x - this.configRect3.x) / 2 + 20,
                y: this.configRect3.y + (this.configRect4.y - this.configRect3.y) / 2,
                text: this.length,
                fontSize: 14 / this.scale,
                fill: '#FFFFFF'
            }
        },
        //球形测量工具
        circleGroup(){
            let that = this;
            return {
                visible: !this.verify && this.ruleForm.tool == '1'? true:false,
                draggable: true,
                dragBoundFunc: function(pos){
                    return {
                        x: that.$refs.center.getNode().x() + pos.x / that.scale < 0
                        ? - that.$refs.center.getNode().x() * that.scale 
                        : that.$refs.center.getNode().x() + pos.x / that.scale > that.widthIn 
                            ? this.absolutePosition().x
                            : pos.x,
                        y: that.$refs.center.getNode().y() + pos.y / that.scale < 0 
                        ? - that.$refs.center.getNode().y() * that.scale 
                        : that.$refs.center.getNode().y() + pos.y / that.scale > that.heightIn 
                            ? this.absolutePosition().y
                            : pos.y
                    }
                }
            }
        },
        configCircle(){
            return {
                x: this.oldWidth - 90,
                y: this.oldHeight / 2,
                radius: 40,
                stroke: '#03C3A0',
                strokeWidth: 2,
                // draggable: true,
            }
        },
        //圆心
        configCenter(){
            if(this.circle){
                return {
                    x: this.circle.x(),
                    y: this.circle.y(),
                    radius: 2,
                    fill: '#03C3A0',
                }
            }
            
        },
        
  	},
    watch:{
        ruleForm:{
            handler(newVal, oldVal){
                let circleGroup = this.$refs.circleGroup.getNode();
                if(newVal.tool == '1'){
                    this.ruler.hide();
                    // this.tr2.hide();
                    circleGroup.show();
                    this.tr3.show();
                    this.layer.draw();
                }else if(newVal.tool == '0'){
                    circleGroup.hide();
                    this.tr3.hide();
                    this.ruler.show();
                    // this.tr2.show();
                    this.layer.draw();
                }
            },
            deep:true
        }
    },
    created(){
        if(this.$route.query.id){
		    this.id = this.$route.query.id;
            this.$api.getReport({
                before_after: 1,
                id: this.id
            }).then(res => {
                if(res.code){
                    this.base = res.data.base;
                    this.ruleForm.width = this.base[0].long;
                    this.ruleForm.tool = this.base[0].tool;
                    this.imageObj.src = this.$baseUrl + this.base[0].image;
                    this.slideText = res.data.case.slide_text;
                    this.slide = res.data.case.slide;
                    this.restore();
                }
            })
	    }else{
		 this.$message.error(this.$t('common.errMsg2'));
		 setTimeout(res=>{
			this.$router.go(-1)
		 },500)
	    }
        //

        // setTimeout(res=>{
        //     this.pushData()
        // },1000)
        
    },
    mounted(){
        document.getElementsByClassName('right-side')[0].style.height = this.configKonva.height + 'px'; 
        this.stage = this.$refs.stage.getNode();
        this.layer = this.$refs.layer.getNode();
        this.imageObj.onload = () => {  
            // this.resizeImg(this.imageObj);
            this.xRay = this.$refs.xRay.getNode();
            this.xRay.rotation(Number(this.base[this.step].angle))
            this.heightIn = this.xRay.height() + 120;
            this.widthIn = this.xRay.width() > this.widthIn ? this.xRay.width() + 100 : this.widthIn;
            //绘制图像编辑工具
            this.tr1 = new Konva.Transformer({
                node: this.xRay,
                rotateAnchorOffset:10,
                enabledAnchors: [],
            });
            this.layer.add(this.tr1);
          
        }
        
        //绘制尺子编辑工具
        this.ruler = this.$refs.rulerGroup.getNode();
        this.toolGroup = this.$refs.toolGroup.getNode();
        // this.tr2 = new Konva.Transformer({
        //     node: this.ruler,
        //     rotateAnchorOffset:30,
        //     enabledAnchors: ['bottom-center', 'top-center'],
        //     boundBoxFunc: (oldBoundBox, newBoundBox) => {
        //         //宽度限制
        //         if (Math.abs(newBoundBox.width) > this.rulerWidth) {
        //             newBoundBox.width = this.rulerWidth;
        //         }
        //         return newBoundBox;
        //     }
        // });
        // this.layer.add(this.tr2);
        //绘制球形编辑工具
        this.circle = this.$refs.circle.getNode();
        this.tr3 = new Konva.Transformer({
            node: this.circle,
            anchorCornerRadius: 10,
            keepRatio: true,
            centeredScaling: true,
            rotateEnabled: false,
            enabledAnchors: ['top-right'],
            // boundBoxFunc: (oldBoundBox, newBoundBox) => {
            //     newBoundBox.height = newBoundBox.width;
            //     return newBoundBox;
            // }

        });
        
        this.layer.add(this.tr3);
        //隐藏球形工具    
        this.$refs.circleGroup.getNode().hide();
        this.tr3.hide();
        const tool = this.$refs.toolGroup.getNode();
        this.ruler.on('mouseenter', () => {
            this.stage.container().style.cursor = 'pointer';
        });
        this.ruler.on('mouseleave', () => {
            this.stage.container().style.cursor = 'default';
        });
        tool.on('mouseenter', () => {
            this.stage.container().style.cursor = 'pointer';
        });
        tool.on('mouseleave', () => {
            this.stage.container().style.cursor = 'default';
        });
        this.layer.draw();
    },
    methods:{
        //画布放大
        onIncrease(){ 
            // this.scale = (this.scale * 10 +  0.1 * 10) / 10;
            this.scale = this.scale / 0.9;
            this.zoom();
        },
        //恢复原尺寸
        onReset(){ 
            this.scale = 1;
            this.zoom();
        },
        //画布缩小
        onReduce(){ 
            // if(this.scale - 0.1 <= 0) return false;
            this.scale = this.scale * 0.9;
            this.zoom();
        },
        //工具复位
        onRestore(){
            this.reload()
        },
        zoom(){
            // this.xRay.width(this.imageObj.width *  this.multiple);
            // this.xRay.height(this.imageObj.height * this.multiple);
            // if(this.verify) this.length = this.getLenght(this.$refs.tool.getNode());

            this.stage.width( this.widthIn * this.scale);
            this.stage.height( this.heightIn * this.scale);
            this.layer.scale({x: this.scale, y: this.scale});
           
            // this.xRay.x(this.widthIn / 2)
            this.layer.draw();

            // this.widthIn = this.oldWidth * this.multiple;
            // this.heightIn = this.oldHeight * this.multiple;
        },
        resizeImg(image){
            let w = image.width; //图片宽度
            let h = image.height + 65; //图片宽度
            let FitWidth = this.configKonva.width //画布宽度
            let FitHeight = this.configKonva.height //画布宽度
            // 当图片比预览区域小时不做任何改变
            if(w < FitWidth && h < FitHeight){
                return false;
            };

            // 当实际图片比例大于预览区域宽高比例时
            if(image.width / image.height >= FitWidth / FitHeight){
                //宽度超出 
                image.height = ((image.height * FitWidth) / image.width) / 2;
                image.width = FitWidth / 2;
            }else{ 
                //高度超出
                image.width = ((image.width * FitHeight) / image.height) / 2;
                image.height = FitHeight / 2;
            }
        },
        
        remeasure(){
            this.verify = false;
            if(this.ruleForm.tool == '1'){
                this.tr3.show();
            }
        },
        //计算尺子长度
        getLenght(ruler){
            let length = Math.sqrt(Math.pow(ruler.width() , 2) + Math.pow(ruler.height() , 2)) ;
            return Math.floor(length / this.proportion / this.multiple * 100) / 100  ;
        },
        //保存数据
        save(){
            this.params[this.step].angle = this.xRay.rotation();
            if(this.step == 0){
                this.step++;
                this.next();
            }else if(this.step == 1){
                this.$api.saveProportion({
                    id: this.id,
                    obj: JSON.stringify(this.params),
                    before_after: 1,
                }).then(res => {
                    if(res.code){
                        return this.$router.push('/post/level?id=' + this.id);
                    }
                    this.$message.error(res.msg)
                })
            }
        },
        next(){ 
            this.verify = false;
            if(this.ruleForm.tool == '1'){
                this.tr3.show();
            }
            this.ruleForm = {
                width: this.base[1].long,
                tool: this.base[1].tool,
            }
            this.imageObj.src = this.$baseUrl + this.base[1].image;
            this.restore();
            this.stage.x(0);
            this.stage.y(0);
            this.$refs.rulerGroup.getNode().x(0)
            this.$refs.rulerGroup.getNode().y(0)
            this.$refs.circleGroup.getNode().x(0)
            this.$refs.circleGroup.getNode().y(0)
            this.layer.draw();
            // this.onReset();

        },
        submit(){
            if(!this.ruleForm.width){
                return this.$message.error('请输入实际长度')
            }
            let ruler = this.$refs.ruler.getNode();
            
            let pixel = this.ruleForm.tool == '0' 
            ? Math.sqrt(Math.pow(ruler.width(), 2) + Math.pow(ruler.height(), 2))
            : 2 * (this.circle.radius() * this.circle.scaleX());
            let proportion = (pixel / this.multiple) / this.ruleForm.width; 
            this.proportion = proportion;
            let params = {
                tool: this.ruleForm.tool,
                long: this.ruleForm.width,
                angle: this.xRay.rotation(),
                pixel,
                proportion,
                coordinates_tool: {
                    scale: this.scale,
                    ruler: this.$refs.ruler.getNode().points(),
                    rect1:{
                        x: this.$refs.rect1.getNode().x() + this.$refs.rulerGroup.getNode().x(),
                        y: this.$refs.rect1.getNode().y() + this.$refs.rulerGroup.getNode().y(),
                    },
                    rect2:{
                        x: this.$refs.rect2.getNode().x() + this.$refs.rulerGroup.getNode().x(),
                        y: this.$refs.rect2.getNode().y() + this.$refs.rulerGroup.getNode().y(),
                    },
                    circle: {
                        x: this.circle.x() + this.$refs.circleGroup.getNode().x(),
                        y: this.circle.y() + this.$refs.circleGroup.getNode().y(),
                        scale: this.circle.scale()
                    }
                }
                
            }
            this.params[this.step] = params;
            this.verify = true;
            this.length = this.getLenght(this.$refs.tool.getNode())
            this.tr3.hide();
            return;
          
        },
        //还原工具
        restore(){
            if(this.base.length && this.base[0].coordinates_tool){ 
                const coordinates = JSON.parse(this.base[this.step].coordinates_tool); 
                if(coordinates.ruler){
                    this.$refs.ruler.getNode().points(coordinates.ruler);
                    this.$refs.rect1.getNode().x(coordinates.rect1.x);
                    this.$refs.rect1.getNode().y(coordinates.rect1.y);
                    this.$refs.rect2.getNode().x(coordinates.rect2.x);
                    this.$refs.rect2.getNode().y(coordinates.rect2.y);
                }
                if(coordinates.circle){
                    this.circle.x(coordinates.circle.x);
                    this.circle.y(coordinates.circle.y);
                    this.circle.scale(coordinates.circle.scale);
                }
                this.scale = coordinates.scale ? coordinates.scale : 1;
                this.zoom();
            }
           
        },
        //退出登录
        logout(){
            this.$confirm('是否退出登录？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.logout().then(res => {
                    if (res.code) {
                        return	this.$router.push('/login')
                    }
                    this.$message.error(res.msg);
                }).catch(err => {
                        // this.$message.error('系统繁忙');
                 })
            }).catch(() => {

            });
        },
        //返回上一步
        lastStep(){
            this.$router.push('/post/upload?id=' + this.id);
        },
        pushData(){
            this.lineWidth = document.getElementsByTagName('canvas')[0].width
            this.lineHeight = document.getElementsByTagName('canvas')[0].height
            this.$refs.lineGrideRef.refreshData({width:this.lineWidth,height:this.lineHeight})
        }
    }
};
</script>
<style scoped>

.warp{
    width: 1440px;
}
.el-main{
    padding: 0 20px;
    background: #f1f5f7;
}
.head {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-header {
  background: #ffffff;
  text-align: right;
}
.btn-logout {
  display: inline-block;
  padding: 8px 17px;
  padding-left: 37px;
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 14px;
  color: #364284;
  margin-right: 50px;
  position: relative;
}
.btn-logout::before {
  content: "";
  background: url(../../assets/img/off.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0%, -50%);
}
.btn-logout:hover {
  cursor: pointer;
}
.tabs{
    margin-bottom: 20px;
}
.tabs .tab{
    width: 128px;
    height: 41px;
    line-height: 41px;
    font-size: 15px;
	background: #F1F5F7;
	border: 1px solid #1F2A66;
	border-radius: 4px;
	font-weight: 400;
	color: #1F2A66;
	text-align: center;
    margin-right: 15px;
}
.tabs .active{
    background: #364284;
    color: #FFFFFF;
}
.tab:focus{
    outline: 0;
}
.btn-submit, .btn-cancel{
    width: 198px;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    font-size: 14px;
    margin: 0 auto;
}
.btn-cancel{
    margin-top: 20px;
}
.text-upload{
    margin-top: 17px;
    text-align: center;
    color: #7ECDF6;
    text-decoration: underline;
    cursor: pointer;
}
.main{
    /* height: ; */
    display: flex;
    justify-content: space-between;
}
.main .canvas{
    width: 76.88%;
    /* height: 66.94%; */
    height: 540px;
    border-radius: 4px;
    background: #000000;
  
}
.main .right-side{
    width: 18%;
    height: 540px;
    background: #ffffff;
    border-radius: 4px;
}
.main .form{
    padding-top: 50px;
    font-size: 13px;
    font-weight: 400;
    color: #000000;

}
#container{
    background: #000000;
    position: relative;
}
.zoom{
    position: absolute;
    right: 30px;
    bottom: 26px;
    z-index: 99;
}
/* 
.main .btn-submit{
    position: absolute;
    bottom: 25px;
    left:50%;
    transform: translate(-50%,0);
} */

</style>
