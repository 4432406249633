<template>
	<v-layer>
		<v-line
		v-for="(item,index) in gridComponents"
		:key="index"
		:x='item.x'
		:y='item.y'
		:strokeWidth='item.strokeWidth'
		:shadowOpacity='item.shadowOpacity'
		:shadowEnabled='item.shadowEnabled'
		:points='item.points'
		:stroke='item.stroke'
		/>          
	</v-layer>
</template>
<script>
export default {
    props: { // TODO:这几个是入参，需要做一下类型校验
    	scale:{
    		type: Number,
    		default: 1
    	},
    	stagePos:{
    		type: Object,
    		default: {x:0,y:0}
    	},
    	CanvasWidth:{
    		type: Number,
    		default: 200
    	},
    	CanvasHeight:{
    		type: Number,
    		default: 200
    	}
    },
    data() {
    	return {
	        gridComponents: [],// 需要插入的网格 components
	        girdSize: 100,// 网格分成多少份 宽度 * 倍数 / 需要多少网格数
	        canvasXHeight: 200,// canvas X、Y 轴距离 stagePos x y 的距离
	        canvasYWidth: 200,
	        solidColor: '#CCCCCC9e',// 实线颜色
	        dashedColor: '#CCCCCC40',// 虚线颜色
	        zeroColor: '#358bf3',// 0 点颜色
	        strokeWidth: 1,
	        shadowOpacity: 0,
	        shadowEnabled: false,
	        xPageSliceTotal: Math.ceil(200 / 10),
	        canvasClientWidth: 9999,//this.CanvasWidth,
	        canvasClientHeight: 9999,//this.CanvasHeight
	    }
	},
    created(){
    	
    	this.girdSize = 100 * this.scale / 20,// 网格分成多少份 宽度 * 倍数 / 需要多少网格数
	    this.canvasXHeight = this.canvasClientHeight - this.stagePos.y,// canvas X、Y 轴距离 stagePos x y 的距离
	    this.canvasYWidth = this.canvasClientWidth - this.stagePos.x,
	    this.xPageSliceTotal = Math.ceil(this.canvasXHeight / this.girdSize)

    	this.xPageSliceTotals()
    	this.xRemaining()
    	this.yPageSliceTotal()
    	this.yRemaining()
    },
	methods:{
		refreshData(n){
			console.log('组件获取监听refreshData')
			console.log(n)

			this.canvasClientWidth = n.width
			this.canvasClientHeight = n.height
			this.canvasXHeight = this.canvasClientHeight - this.stagePos.y,// canvas X、Y 轴距离 stagePos x y 的距离
		    this.canvasYWidth = this.canvasClientWidth - this.stagePos.x,
		    this.xPageSliceTotal = Math.ceil(this.canvasXHeight / this.girdSize)

	    	this.xPageSliceTotals()
	    	this.xRemaining()
	    	this.yPageSliceTotal()
	    	this.yRemaining()
		},
		xPageSliceTotals() { // 从 pageSlicePos.y 处开始往 Y 轴正方向画 X 轴网格
			const { gridComponents, stagePos, strokeWidth, shadowOpacity, shadowEnabled, solidColor, dashedColor } = this
			const xPageSliceTotal = Math.ceil(this.canvasXHeight / this.girdSize);
			console.log('xPageSliceTotal',xPageSliceTotal)
			for (let i = 0; i < xPageSliceTotal; i++) {
				const params = {
					x: 0 - stagePos.x,
					y: this.girdSize * i,
					strokeWidth,
					shadowOpacity,
					shadowEnabled,
					points: [0, 0, this.canvasClientWidth, 0],
					stroke: i === 0 ? this.zeroColor : i % 5 === 0 ? solidColor : dashedColor
				}
				gridComponents.push(params)
			}
		},
	    // 从 pageSlicePos.y 处开始往 Y 轴负方向画 X 轴网格
	    xRemaining() {
	    	const { gridComponents, stagePos, strokeWidth, shadowOpacity, shadowEnabled, solidColor, dashedColor } = this
	    	const xRemaining = Math.ceil(this.canvasXHeight / this.girdSize);
	    	for (let i = 0; i < xRemaining; i++) {
	    		const params = {
	    			x: 0 - stagePos.x,
	    			y: -this.girdSize * i,
	    			strokeWidth,
	    			shadowOpacity,
	    			shadowEnabled,
	    			points: [0, 0, this.canvasClientWidth, 0],
	    			stroke: i === 0 ? this.zeroColor : i % 5 === 0 ? solidColor : dashedColor
	    		}
	    		gridComponents.push(params)
	    	}
	    },
	    // 从 pageSlicePos.x 处开始往 X 轴正方向画 Y 轴网格
	    yPageSliceTotal() {
	    	const { gridComponents, stagePos, strokeWidth, shadowOpacity, shadowEnabled, solidColor, dashedColor } = this
	    	const yPageSliceTotal = Math.ceil(this.canvasXHeight / this.girdSize);
	    	for (let i = 0; i < yPageSliceTotal; i++) {
	    		const params = {
	    			x: this.girdSize * i,
	    			y: 0 - stagePos.y,
	    			strokeWidth,
	    			shadowOpacity,
	    			shadowEnabled,
	    			points: [0, 0, 0, this.canvasClientHeight],
	    			stroke: i === 0 ? this.zeroColor : i % 5 === 0 ? solidColor : dashedColor
	    		}
	    		gridComponents.push(params)
	    	}
	    },
	    // 从 pageSlicePos.x 处开始往 X 轴负方向画 Y 轴网格
	    yRemaining() {
	    	const { gridComponents, stagePos, strokeWidth, shadowOpacity, shadowEnabled, solidColor, dashedColor } = this
	    	const yRemaining = Math.ceil(this.canvasXHeight / this.girdSize);
	    	for (let i = 0; i < yRemaining; i++) {
	    		const params = {
	    			x: -this.girdSize * i,
	    			y: 0 - stagePos.y,
	    			strokeWidth,
	    			shadowOpacity,
	    			shadowEnabled,
	    			points: [0, 0, 0, this.canvasClientHeight],
	    			stroke: i === 0 ? this.zeroColor : i % 5 === 0 ? solidColor : dashedColor
	    		}
	    		gridComponents.push(params)
	    	}
	    },
	}


}
</script>